'use client'

import { LazyMotion } from 'framer-motion'
import { ThemeProvider as Theme } from 'next-themes'

import { TailwindIndicator } from '@/components/tailwind-indicator'

const loadFeatures = () => import('../lib/features').then((res) => res.default)

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  return (
    <Theme enableSystem attribute='class' defaultTheme='system'>
      <LazyMotion strict features={loadFeatures}>
        {children}
      </LazyMotion>
      <TailwindIndicator />
    </Theme>
  )
}
